import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { pluck, take } from 'rxjs/operators';
import { Country } from '../shared/phone-number';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LocalizationService {
  apiUrl = 'address/';
  constructor(
    private _httpClient: HttpClient
  ) { }

  GetCountries = (): Observable<Country[]> =>
    this._httpClient
      .get<Country[]>(
        `${environment.localizationApiUrl}${'country/getall'}`
      )
      .pipe(pluck('data'));

      GetAreas = (): Observable<Country[]> =>
      this._httpClient
        .get<Country[]>(
          `${environment.localizationApiUrl}${'Area/getall'}`
        )
        .pipe(pluck('data'));

        GetSubAreas = (): Observable<Country[]> =>
        this._httpClient
          .get<Country[]>(
            `${environment.localizationApiUrl}${'Subarea/getall'}`
          )
          .pipe(pluck('data'));

  GetCountry = (id: string): Observable<any> =>
    this._httpClient
      .get<any>(`${environment.localizationApiUrl}country/get?id=` + id)
      .pipe(pluck('data'),take(1));

      GetAreaById = (id: string): Observable<any> =>
      this._httpClient
        .get<any>(`${environment.localizationApiUrl}area/GetById?id=` + id)
        .pipe(pluck('data'),take(1));

        GetAreaByCity = (id: string): Observable<any> =>
        this._httpClient
          .get<any>(`${environment.localizationApiUrl}area/GetAreaByCity?id=` + id)
          .pipe(pluck('data'),take(1));

          GetSubAreaByArea = (id: string): Observable<any> =>
          this._httpClient
            .get<any>(`${environment.localizationApiUrl}area/GetSubAreaByArea?id=` + id)
            .pipe(pluck('data'),take(1));


  GetAddressById = (id: string): Observable<any> =>
    this._httpClient
      .get<any>(
        `${environment.localizationApiUrl}${this.apiUrl + 'get?id=' + id}`
      )
      .pipe(pluck('data'), take(1));
      
  GetCustomerAddressByPhoneNumber = (Phonenumber: string): Observable<any> =>
    this._httpClient
      .get<any>(
        `${environment.companyApi}${'Customer/GetCustomerAddressByPhoneNumber?Phonenumber=' + Phonenumber
        }`
      )
      .pipe(take(1), pluck('data'));



  GetCities = (): Observable<any> =>
    this._httpClient
      .get<Country[]>(`${environment.localizationApiUrl}${'city/getAll'}`)
      .pipe(pluck('data'),take(1));

  SearchAddress = (keyword: string, cityid: any): Observable<any> =>
    this._httpClient
      .get<any>(
        `${environment.localizationApiUrl}${'area/searchall?keyword=' + keyword + '&cityid=' + cityid
        }`
      )
      .pipe(pluck('data'),take(1));


    //--------------------------------------------------------------------

  CreateAddress = (address: any) =>
    this._httpClient.post(
      `${environment.companyApi}${this.apiUrl + 'create'}`,
      address
    ).pipe(take(1));

    GetByPhone = (phone: string): Observable<any> =>
      this._httpClient
        .get<any>(`${environment.companyApi}${'Customer/GetByPhone=' + phone}`)
        .pipe(pluck('data'),take(1));

  GetCompanyAddress(companyid: string, cityid: string, keyword?: any): Observable<any> {
    return this._httpClient.get<any>(`${environment.companyApi}${'Dispatcher/GetCompanyAddress?companyid=' + companyid + '&cityid=' + cityid + '&keyword=' + keyword}`).pipe(pluck('data'),take(1));
  }

  GetCompanyNewAddress(companyid: string, cityid: string, keyword?: any): Observable<any> {
    return this._httpClient.get<any>(`${environment.companyApi}${'Dispatcher/GetCompanyNewAddress?companyid=' + companyid + '&cityid=' + cityid + '&keyword=' + keyword}`).pipe(pluck('data'),take(1));
  }



  GetAddressDdl(keyword?: any): Observable<any> {
    return this._httpClient.get<any>(`${environment.companyApi}${'Dispatcher/GetAddressDdl?'}`).pipe(pluck('data'),take(1));
  }
  GetCompanyCities(companyid: string): Observable<any> {
    return this._httpClient.get<any>(`${environment.companyApi}${'Dispatcher/GetCompanyCities?companyid=' + companyid}`).pipe(pluck('data'),take(1));
  }


  GetCompanyNewCities(companyid: string): Observable<any> {
    return this._httpClient.get<any>(`${environment.companyApi}${'Dispatcher/GetCompanyNewCities?companyid=' + companyid}`).pipe(pluck('data'),take(1));
  }

  GetBranchForAddress(companyid: string, addressid : string): Observable<any> {
    return this._httpClient.get<any>(`${environment.companyApi}${'Dispatcher/GetBranchForAddress?companyid=' + companyid + '&addressid=' + addressid}`).pipe(pluck('data'),take(1));
  }
}
