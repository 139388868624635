import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { pluck, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderfollowupService {

  constructor(private _http: HttpClient) { }

  getordersfollowup = (usertype: string) => { return this._http.get<any>(`${environment.companyApi}${'orderfollowup/GetOrdersFollowUpByUser?usertype=' + usertype}`).pipe(pluck('data'), take(1)); }
  getcomplaincategories = () => { return this._http.get<any>(`${environment.companyApi}${'complain/GetAll'}`).pipe(pluck('data'), take(1)); }
  getcomplaincategoriesTree = () => { return this._http.get<any>(`${environment.companyApi}${'complain/GetTree'}`).pipe(pluck('data'), take(1)); }
  getcomplainSubcategoriesById = (categoryid:any) => { return this._http.get<any>(`${environment.companyApi}${'complain/GetAllSubCategory?Categoryid='+categoryid}`).pipe(pluck('data'), take(1)); }
  getcomplainSubcategories = () => { return this._http.get<any>(`${environment.companyApi}${'complain/GetAllSubCategory'}`).pipe(pluck('data'), take(1)); }
  getcomplainSubcategoriesByRefId = (id: any) => { return this._http.get<any>(`${environment.companyApi}${'complain/GetSubCategoriesByRefId?id=' + id}`).pipe(pluck('data'), take(1)); }
  getGeneralcomplainsByCustomerId = (id: any) => { return this._http.get<any>(`${environment.companyApi}${'Generalcomplain/Get?id=' + id}`).pipe(pluck('data'), take(1)); }
  deleteOrderFollowupDetails = (id: any) => this._http.delete(`${environment.companyApi}${'OrderFollowUp/DeleteOrderFollowUpDetails?id=' + id}`).pipe(take(1))
  deletegeneralComplain = (id: any) => this._http.delete(`${environment.companyApi}${'GeneralComplain/delete?id=' + id}`).pipe(take(1))
  getordersfollowupview = (id: any) => { return this._http.get<any>(`${environment.companyApi}${'orderfollowup/GetOrdersFollowUpViewById?id=' + id}`).pipe(pluck('data'), take(1)); }
  // getOrdersFollowUpviewByCustomer = (id: any) => { return this._http.get<any>(`${environment.companyApi}${'orderfollowup/GetOrdersFollowUpViewByCustomerId?id=' + id}`).pipe(pluck('data'), take(1)); }
  getOrdersFollowUpviewByCustomer = (body: any) => { return this._http.post<any>(`${environment.companyApi}${'orderfollowup/GetOrdersFollowUpViewByCustomerId' }` , body).pipe(pluck('data'), take(1)); }
  getOrdersFollowUpviewByOrderid = (id: any) => { return this._http.get<any>(`${environment.companyApi}${'orderfollowup/GetOrdersFollowUpViewByOrderId?id=' + id}`).pipe(pluck('data'), take(1)); }


  deleteOrderFollowup (id: any, ordercase: any) {return this._http.delete(`${environment.companyApi}${'OrderFollowUp/DeleteFollowUp?id=' + id + '&ordercase=' + ordercase}`).pipe(take(1))}
  createOrderFollowUp = (order: any) => this._http.post(`${environment.companyApi}${'orderfollowup/CreateFollowUp'}`, order).pipe(take(1)); //{ this._cartService.beforeSubmit(); return}
  createOrderFollowUpDetails = (order: any) => this._http.post(`${environment.companyApi}${'orderfollowup/CreateFollowUpDetails'}`, order).pipe(take(1));
  getordersfollowupdetailsview = (id: any) => { return this._http.get<any>(`${environment.companyApi}${'orderfollowup/GetOrdersFollowUpDetailsById?id=' + id}`).pipe(pluck('data'), take(1)); }
  updateFollowup = (orderFollowup: any) => this._http.put(`${environment.companyApi}${'orderfollowup/UpdateOrderFollowUp'}`, orderFollowup).pipe(take(1))
  updateFollowupDetails = (orderFollowupDetails: any) => this._http.put(`${environment.companyApi}${'orderfollowup/UpdateOrderFollowUpDetails'}`,  orderFollowupDetails ).pipe(take(1))

}

