import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private _httpClient: HttpClient) { }

  createUser(user: any) {
    return this._httpClient.post(`${environment.keycloakApiUrl}${'registration'}${'/'}${'register'}`, user).pipe(take(1));
  }
  createGlobalAgentUser(agentUser:any){
    return this._httpClient.post(`${environment.keycloakApiUrl}registration/RegisterAgent`, agentUser).pipe(take(1));
  }
}
