import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  state = new BehaviorSubject({ licenceWarning: false });


  constructor(private _httpClient: HttpClient ) { }

  updateLicenceWarning = (warning: boolean) => this.state.pipe(take(1)).subscribe(st => { this.state.next({ ...st, licenceWarning: warning }) });

  getServerTime = () => this._httpClient.get<string>(`${environment.companyApi}${'utils'}`).pipe(take(1));


}
