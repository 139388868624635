import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { pluck, shareReplay, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  // createCompanyPayment(arg0: { companyid: any; paymentOption: any; currencyOption: any; }) {
  //   throw new Error('Method not implemented.');
  // }

  constructor(
    private _httpClient: HttpClient) { }
  private allowed_extensions = ['png', 'jpg', 'bmp', 'jpeg', 'svg'];
  apiUrl = 'Company/';
  apiUrl2 = 'Companyinfo/';

  getAllPopUpCompanyPhone() {
    return this._httpClient.get(`${environment.companyApi}${this.apiUrl}getAllPopUpCompanyPhone`).pipe(pluck('data'), take(1));
  }

  getPopUpCompanyPhone(companyId: string) {
    return this._httpClient.get(`${environment.companyApi}${this.apiUrl}getPopupPhoneNumber?companyId=${companyId}`).pipe(pluck('data'), take(1));
  }

  addPopUpCompanyPhone(data : any) {
    return this._httpClient.post(`${environment.companyApi}${this.apiUrl}addPopUpCompanyPhone`, data).pipe(take(1))
  }


  deletePopUpCompanyPhone(companyId: string) {
    return this._httpClient.delete(`${environment.companyApi}${this.apiUrl}deletePopUpCompanyPhone?companyId=${companyId}`).pipe(take(1));
  }

  getCompanyProfile = () => this._httpClient.get(`${environment.companyApi}${this.apiUrl2 + 'GetProfileInfo'}`).pipe(pluck('data'),take(1));

  updateCompanyProfile = (info: any) => this._httpClient.put(`${environment.companyApi}${'Companyinfo/UpdateCompanyProfile'}`, info).pipe(take(1))


  CreateCompanyMarketingService(body: any) {
    return this._httpClient.post(`${environment.companyApi}${'Companyinfo/CreateCompanyMarketingService'}`, body).pipe(take(1));
  }


  getCompanyChannels(companyid?: string) {
    return this._httpClient.get(`${environment.companyApi}companyinfo/getcompanychannels${companyid == undefined ? '' : '?companyId=' + companyid}`).pipe(pluck('data'), shareReplay(5));
  }


  getCompanyServices(companyid?: string) {
    return this._httpClient.get(`${environment.companyApi}companyinfo/getcompanyservices${companyid == undefined ? '' : '?companyId=' + companyid}`).pipe(pluck('data'), shareReplay(5));
  }



  getCompanyMarketingService(): Observable<any> {
    return this._httpClient.get<any>(`${environment.companyApi}${'companyinfo/GetCompanyMarketingService'}`).pipe(pluck('data'));
  }



  createCompanyService = (servicecharge: any) => this._httpClient.post(`${environment.companyApi}${'companyinfo/createcompanyservice'}`, servicecharge).pipe(take(1));

  getAllCompanyChannels() {
    return this._httpClient.get(`${environment.companyApi}Ordersource/GetAllOrderSources`).pipe(pluck('data'),take(1));
  }


  UpdateCompanyChannelAvtivation(data: any) {
    return this._httpClient.put(`${environment.companyApi}companyinfo/UpdateCompanyChannelAvtivation`, data).pipe(pluck('status'),take(1));

  }

  UpdateCompanyServicesAvtivation(data: any) {
    return this._httpClient.put(`${environment.companyApi}companyinfo/UpdateCompanyServicesAvtivation`, data).pipe(pluck('status'),take(1));

  }
  getall(): Observable<any[]> {
    return this._httpClient
      .get(
        `${environment.companyApi}${this.apiUrl + 'GetAll'
        }`
      )
      .pipe(take(1), pluck('data'));
  }
  getById(): Observable<any> {
    return this._httpClient
      .get(
        `${environment.companyApi}${this.apiUrl + 'Get'
        }`
      )
      .pipe(shareReplay(1), pluck('data'));
  }
  getInfoById(): Observable<any[]> {
    return this._httpClient
      .get(
        `${environment.companyApi}${'companyinfo/y' + 'Get'
        }`
      )
      .pipe(take(1), pluck('data'));
  }

  uploadCompanyLogo(logo: File): Observable<any> {
    let file: File = logo;
    const valid = this._checkValidFiles(file)
    if (valid) {
      const formData = new FormData();
      formData.append('file', file)
      return this._httpClient.post(`${environment.companyApi}${this.apiUrl + 'uploadLogo'}`, formData).pipe(take(1));
    }
    return of({});
  }
  private _checkValidFiles(file: File): boolean {
    let validity = false
    const ext = file.name.split('.')[file.name.split('.').length - 1];
    if (this.allowed_extensions.lastIndexOf(ext) != -1)
      validity = true
    return validity;
  }



}
