import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { pluck, take, shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  constructor(private _httpClient: HttpClient) { }

  checkIfCustomerComplainedOrder = (orderid: string, customerid: string) => this._httpClient.get<any[]>(`${environment.companyApi}${'OrderFollowUp/CheckIfCustomerComplainedOrder?orderid=' + orderid + '&customerid=' + customerid}`).pipe(take(1));

  createOrder = (order: any) => { return this._httpClient.post(`${environment.companyApi}${'Order/CreateCallCenter'}`, order).pipe(take(1)); } //{ this._cartService.beforeSubmit(); return}

  getOrder = () => this._httpClient.get<any[]>(`${environment.companyApi}${'Order/getall'}`).pipe(pluck('data'), take(1));

  getOrderStatuses = (orderid: string) => this._httpClient.get<any[]>(`${environment.companyApi}${'Order/GetOrderStasusesHistory?orderid=' + orderid}`).pipe(pluck('data'), take(1));

  getOrderSource = () => this._httpClient.get<any>(`${environment.companyApi}${'Order/GetAllOrderSource'}`).pipe(pluck('data'), take(1));

  getOrderSound = (id?: string[]) => { let query = '?'; id?.forEach(i => { query += 'id=' + i + '&' }); return this._httpClient.get<any>(`${environment.companyApi}${'order/getsound' + (query || '?id=')}`).pipe(pluck('data'), take(1)); }

  getOrderPrinterWeb = (id: string, orderid: string) => this._httpClient.get<any[]>(`${environment.companyApi}${'Order/GetOrderPrinterWeb?ID=' + id + '&Orderid=' + orderid}`).pipe(take(1), pluck('data'));

  getOrderDeliveryCost = (addressid: string, branchid: string) => this._httpClient.get<any[]>(`${environment.companyApi}${'Order/GetOrderDeliveryCost?AddressID=' + addressid + '&Branchid=' + branchid}`).pipe(take(1), pluck('data'));

  getOrderView = (orderid: string) => this._httpClient.get<any[]>(`${environment.companyApi}${'Order/getOrderView?id=' + orderid}`).pipe(pluck('data'), take(1));

  getCustomerOrders = (customerId: string) => this._httpClient.get<any[]>(`${environment.companyApi}${'Order/GetCustomerOrders?customerid=' + customerId}`).pipe(pluck('data'), take(1));

  getCustomerCompanyOrders = (customerId: string, companyid: string) => this._httpClient.get<any[]>(`${environment.companyApi}${'Order/GetCustomerCompanyOrders?customerid=' + customerId + '&companyId=' + companyid}`).pipe(pluck('data'), take(1));

  getCustomerOrdersForCompany = (customerId: string, companyid: string) => this._httpClient.get<any[]>(`${environment.companyApi}${'Order/GetCustomerOrdersForCompany?customerId=' + customerId + '&companyId=' + companyid}`).pipe(pluck('data'), take(1));

  getOrderHistory = (orderId: string) => this._httpClient.get<any[]>(`${environment.companyApi}${'Order/OrderHistory?id=' + orderId}`).pipe(pluck('data'), take(1));

  setOrderNextStatus(orderid: string, isfirst?: boolean, isdelivery?: boolean, deliverycompany?: string, latitude?: number, longtitude?: number, branchname?: string, phonenumber?: string) {
    return this._httpClient.put<any>(`${environment.companyApi}${'Order/setOrderNextStatus'}`, { orderid, isfirst, isdelivery, deliverycompany, latitude, longtitude, branchname, phonenumber }).pipe(take(1))
  }

  UpdateIsSeen(orderid: string) { 
    return this._httpClient.put<any>(`${environment.companyApi}${'Order/UpdateIsSeen'}`, { orderid }).pipe(take(1)) 
  }

  updatePromoCode = (id: string, customerid: string) => this._httpClient.put<any>(`${environment.companyApi}${'promocode/UpdatePromoCodeUse'}`, { id, customerid }).pipe(take(1));

  printingOrder = (orderid: string, branchid: string) => this._httpClient.put<any>(`${environment.companyApi}${'Order/printingOrder'}`, { orderid, branchid }).pipe(take(1));

  declineOrder = (orderid: string, reason: string) => this._httpClient.put<any>(`${environment.companyApi}${'Order/declineorder'}`, { orderid, reason }).pipe(take(1))

  getPrintingOrder = (Id: string) => this._httpClient.get<any[]>(`${environment.companyApi}${'Order/GetOrderPrinterWeb?id=' + Id}`).pipe(pluck('data'), take(1));


  getSchedualedOrder = (branchids: string[]): Observable<any[]> => {
    let query = '?';
    branchids.forEach(i => { query += 'BranchId=' + i + '&' })
    return this._httpClient.get<any>(`${environment.companyApi}${'Order/getScheduledOrders' + query}`).pipe(pluck('data'), take(1));
  }

  getOrderByBranch = (branchids: string[]): Observable<any[]> => {
    let query = '?';
    branchids.forEach(i => { query += 'branchid=' + i + '&' })
    return this._httpClient.get<any>(`${environment.companyApi}${'Order/getOrderByBranchesId' + query}`).pipe(shareReplay(1), pluck('data'));
  }

  printlocal = () => this._httpClient.get<any[]>(`${environment.printerurl}${'Printing/Print'}`).pipe(take(1));

  movetocurrent = (orderid: string) => this._httpClient.put<any>(`${environment.companyApi}${'Order/MovetoCurrent'}`, { orderid }).pipe(take(1))

  cancelorder = (orderid: string, deliverycompany: string, branchid: string, scheduled: boolean) => this._httpClient.put<any>(`${environment.companyApi}${'Order/CancelOrder'}`, { orderid, deliverycompany, branchid, scheduled }).pipe(take(1))

  getbranchorderhistory = (branchids?: string[], pagenumber: number = 1, pagesize: number = 10): Observable<any[]> => {
    let query = '?';
    branchids?.forEach(i => { query += 'branchid=' + i })
    return this._httpClient.get<any>(`${environment.companyApi}${'Order/GetBranchOrderHistory' + query + '&pagenumber=' + pagenumber + '&pagesize=' + pagesize}`).pipe(pluck('data'), take(1));
  }


  createReminderOrder = (orderid: any, reminder: any) => this._httpClient.post(`${environment.companyApi}${'Order/CreateReminderOrder'}`, { orderid, reminder }).pipe(take(1)); //{ this._cartService.beforeSubmit(); return}



}
